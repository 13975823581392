import { For, Show, createEffect, splitProps } from "solid-js";
import { ShowMaterialLessonGroupViewProps } from "../../../models";
import { Item } from "./item";
import { elementStyleToHTMLStyle } from "../../../methods";
export function Group(props: ShowMaterialLessonGroupViewProps) {
  const [local, others] = splitProps(props, ["group", "style"]);
  let ref: HTMLDivElement = undefined;
  createEffect(() => {
    if (ref) {
      ref.setAttribute("style", elementStyleToHTMLStyle(local.group.style));
    }
  });
  return (
    <div {...others} ref={ref}>
      <Show when={local.group.items && local.group.items.length > 0} fallback={local.group.name ?? "Unedited Group"}>
        <For each={local.group.items}>
          {(item, index) => {
            return <Item item={item} />;
          }}
        </For>
      </Show>
    </div>
  );
}
